<template :key="appVersion">
  <the-header class=""></the-header>
  <router-view
    v-if="!isLoading"
    style="margin-top: 72px"
    v-slot="{ Component }"
  >
    <transition name="route-transition" mode="out-in">
      <component :is="Component" class="container-fluid"></component>
    </transition>
  </router-view>

  <div
    v-else
    class="spinner-border p-5"
    role="status"
    style="min-height: 100%; margin-top: 80px"
  >
    <span class="">Loading...</span>
  </div>
</template>

<script>
  import TheHeader from './components/layout/TheHeader.vue';

  export default {
    data() {
      return {
        isLoading: false,
      };
    },
    components: { TheHeader },
    async created() {
      // verificam si cream verificarea automata a versiunii aplicatiei
      const appVersion = '1.6';
      // const appVer = await this.axios({
      //   method: 'get',
      //   url: 'ver.json',
      // });
      // const appVersion = appVer.data.appVersion;
      console.log('appVersion: ' + appVersion);
      if (localStorage.getItem('appVersion') === null) {
        localStorage.setItem('appVersion', '0');
      }
      setInterval(() => {
        if (localStorage.getItem('appVersion') != appVersion) {
          // clearInterval(checkAppVersion);
          alert(
            'App version changes to ' +
              appVersion +
              '!' +
              "\nA reload will be made after pressing 'OK'."
          );
          localStorage.setItem('appVersion', appVersion);
          location.reload();
        }

        console.log('S-a verificat versiunea: ' + appVersion);

        // pentru cazul in care nu sunt platit pana in data de 05.XI.2021
        // var today = new Date();
        // var varDate = new Date('2021-11-15');
        // var paymentCheck = false;
        // if (today >= varDate && paymentCheck === true) {
        //   clearInterval(checkAppVersion);
        //   alert('App is not payed yet!' + '\nYou will be loggedout!');
        //   this.$store.dispatch('logoutUser');
        //   // redirectionare catre login ('/login')
        //   // this.$router.replace("/login");
        //   location.reload();
        // }
      }, 1 * 5 * 1000);

      // punem loaderul in fata si incercam autologin
      this.isLoading = true;
      await this.$store.dispatch('tryLogin');
      this.isLoading = false;
    },
  };
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

// tranzitiile aferente pentru route
.route-transition-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-transition-enter-active {
  transition: all 0.3s ease-out;
}
.route-transition-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-transition-leave-active {
  transition: all 0.3s ease-in;
}
</style>
